import { createContext, useContext, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import useUidParam from 'src/shared/hooks/useUidParam';
import { IContextChildren, IGeneralObjectType } from 'src/site/util';
import { axiosAuthInstance } from './axiosAuthInstance';
import { AuthContext } from '../AuthContextProvider';

export const ApiContext = createContext<IGeneralObjectType>({});

export function useApiContext() {
  return useContext(ApiContext);
}

const ApiContextProvider = ({ children }: IContextChildren) => {
  const { instance } = useMsal();
  const { account } = useContext(AuthContext);
  const uid = useUidParam();

  const api = axiosAuthInstance(instance, account, uid);

  const valueObj = useMemo(() => ({ api }), [api]);

  return <ApiContext.Provider value={valueObj}>{children}</ApiContext.Provider>;
};

export default ApiContextProvider;
