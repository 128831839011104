import { useCasFilteredNavigation } from 'src/site/routes';

export function useNavigationMenuData() {
  const navData = useCasFilteredNavigation();

  const formattedNavData = navData.map((menu) => ({
    title: menu.menuTitle,
    path: menu.path,
    icon: menu.icon,
    children: menu.subMenus.map((subMenu) => ({
      title: subMenu.title,
      path: subMenu.path,
    })),
  }));

  return [
    {
      subheader: 'Compass Portal',
      items: formattedNavData,
    },
  ];
}
