import { useState } from 'react';
import { Stack } from '@mui/material';
import { CasMRTCellRenderDate, CasMRTable, CasMRTCellRenderDecimal, CasErrorDisplay } from 'src/shared/components';
import LrpQuotesFooter from './LrpQuotesFooter';
import LrpTypeSelector from './LrpTypeSelector';

type LrpQuotesTabProps = {
  tabData: any;
  className: string;
};

export default function LrpQuotesTab({ tabData, className }: LrpQuotesTabProps) {
  const [selectedType, setSelectedType] = useState(tabData?.types?.[0]);

  const columns = [
    { accessorKey: 'endDate', header: 'End Date' ,Cell: CasMRTCellRenderDate},
    { accessorKey: 'coveragePrice', header: 'Floor Price', Cell: CasMRTCellRenderDecimal },
    { accessorKey: 'costPerCwtAmount', header: 'Cost Cwt', Cell: CasMRTCellRenderDecimal   },
  ];

  if (!tabData) return <CasErrorDisplay message="No active quotes" sx={{ textAlign: "center" }}/>;
  
  return (
    <Stack my={2} sx={{ textAlign: 'center' }}>
      <LrpTypeSelector
        className={className}
        types={tabData.types}
        selectedType={selectedType}
        onTypeChange={setSelectedType}
      />
      <CasMRTable
        data={selectedType?.levels || []}
        columns={columns}
        enableExpanding
        enableTopToolbar={false}
        enablePagination={false}
        enableSorting={false}
      />

      <LrpQuotesFooter className={className} />
    </Stack>
  );
}
