import { Typography } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { CasErrorDisplay } from 'src/shared/components';
import CasLoader from 'src/shared/components/CasLoader/CasLoader';
import { DateFormat, formatDate } from 'src/shared/util/casMoment';

import LrpQuotesContent from './LrpQuotesContent';

type QuotesContainerProps = {
  queryResult: UseQueryResult<any, any>;
  salesEffectiveDate: string;
};

export default function LrpQuotesContainer({ queryResult, salesEffectiveDate}: QuotesContainerProps) {
  const { isFetching, isError, data } = queryResult;

  if (isFetching) return <CasLoader />;

  if (isError) return <Typography mt={4}>Error retrieving data from the server.</Typography>;

  if (data?.result?.length === 0) return <CasErrorDisplay message="No active quotes" sx={{ textAlign: "center" }}/>;

  return (
    <>
      {!data?.quotesAreActive && (
        <div className="noActiveQuotes-warning">
          {formatDate(salesEffectiveDate, DateFormat.MDY)} quotes are no longer active
        </div>
      )}
      <LrpQuotesContent data={data} />
    </>
  );
}
