import { Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { SubMenu } from 'src/site/routes';
import CasLink from 'src/shared/components/CasLink/CasLink';

type MenuPageLayoutProps = {
  subMenus: SubMenu[];
};

export default function MenuPageLayout({ subMenus }: MenuPageLayoutProps) {
  const navigate = useNavigate();

  return (
    <Stack gap="0.5rem">
      {subMenus.map((subMenu) => (
        <CasLink key={subMenu.title} onClick={() => navigate(subMenu.path)}>
          {subMenu.title}
        </CasLink>
      ))}
    </Stack>
  );
}
