import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

// local
import { CustomNavigationClient, IContextChildren } from 'src/site/util';
import { msalConfig } from 'src/site/config';

const CasMsalProvider = ({ children }: IContextChildren) => {
  const navigate = useNavigate();
  const instance = useMemo(() => new PublicClientApplication(msalConfig),[]);

  useEffect(() => {
    const initializeMsalInstance = async () => {
      await instance.initialize();
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
      }

      instance.addEventCallback(async (event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          const payload = event.payload as AuthenticationResult;
          const { account } = payload;
          instance.setActiveAccount(account);
        }
      });

      const navigationClient = new CustomNavigationClient(navigate);
      instance.setNavigationClient(navigationClient);
    };

    initializeMsalInstance();
  }, [instance, navigate]);

  return <MsalProvider instance={instance}>{children}</MsalProvider>;
};

export default CasMsalProvider;