
import { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import useUpdateMutation from 'src/shared/hooks/reactQuery/useUpdateMutation';

interface ToggleButtonOption {
  label: string;
  value: number | boolean;
  color?: 'standard' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

type CasToogleButtonProps = {
  defaultValue: string;
  url: string;
  options: ToggleButtonOption[]
  preparePayloadForSave: Function;
};

const CasToggleButtonGroup = ({ defaultValue, url, options, preparePayloadForSave }: CasToogleButtonProps) => {
  const [activeToggle, setActiveToggle] = useState<string | null>(defaultValue);

  const mutation = useUpdateMutation({ url, onSuccess: () => null });

  const { isPending, mutate } = mutation;

  const handleToggle = async (_: React.MouseEvent<HTMLElement>, newToggleValue: string | null) => {

    if (newToggleValue === null) return;

    const previousToggleValue = activeToggle;
    setActiveToggle(newToggleValue);
    const payload = preparePayloadForSave(newToggleValue)
    mutate(payload, {
      onError: () => setActiveToggle(previousToggleValue)
    });
  };

  return (
    <ToggleButtonGroup
      value={activeToggle}
      exclusive
      onChange={handleToggle}
      disabled={isPending}
    >
      {options.map(({ label, ...rest }) => (
        <ToggleButton {...rest}>{label}</ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default CasToggleButtonGroup;
