import { useState } from 'react';
import {
  CasBaseDialog,
  CasButton,
  CasRHFTextField,
  CasLoaderWithBackdrop,
  CasDeleteButton,
} from 'src/shared/components';
import { Typography } from '@mui/material';
import { DeleteSchema } from 'src/shared/schemas/DeleteForm';
import { onSuccessEnqueueSnackbar } from 'src/shared/util/onSuccessEnqueueSnackbar';
import useDeleteMutation from 'src/shared/hooks/reactQuery/useDeleteMutation';

import { ICasBaseDialog } from '../CasDialog/CasBaseDialog';
import CasReactHookForm, { FormMethodType } from '../CasForm/CasReactHookForm';

interface ICasDeletePopUp extends Omit<ICasBaseDialog, 'title'> {
  entity: string;
  entityTitle: string;
  id: string;
  onDeleted: VoidFunction;
}

export default function CasDeletePopUp({
  entity,
  entityTitle,
  id,
  onDeleted,
  onClose,
  ...other
}: ICasDeletePopUp) {
  const [formMethods, setFormMethods] = useState<FormMethodType>(null);

  const mutation = useDeleteMutation({
    url: `${entity}/${id}`,
    onSuccess: (data) => {
      onDeleted();
      onSuccessEnqueueSnackbar(data);
    }
  });

  const { mutate, isPending } = mutation;

  const handleEntityDelete = () => mutate({});

  return (
    <CasBaseDialog
      {...other}
      onClose={onClose}
      title={`Delete ${entityTitle}`}
      content={
        <>
          {isPending && <CasLoaderWithBackdrop />}
          <Typography>
            This Record will be permanently deleted from the system. This action can not be undone.
          </Typography>
          <CasReactHookForm schema={DeleteSchema} setFormMethods={setFormMethods}>
            <CasRHFTextField
              name="deleteInput"
              label="To confirm, type DELETE below and Press Enter"
            />
          </CasReactHookForm>
        </>
      }
      actions={
        <>
          <CasButton variant="outlined" onClick={onClose}>
            Cancel
          </CasButton>
          <CasDeleteButton onClick={() => formMethods?.handleSubmit(handleEntityDelete)()} />
        </>
      }
    />
  );
}
