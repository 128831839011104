import { useState } from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import CasIconButton from 'src/shared/components/CasIconButton/CasIconButton';
import { paths } from 'src/site/routes';
import useUpdateMutation from 'src/shared/hooks/reactQuery/useUpdateMutation';
import { CasLoaderWithBackdrop } from 'src/shared/components';
import { onSuccessEnqueueSnackbar } from 'src/shared/util/onSuccessEnqueueSnackbar';

type ImpersonateUserAppBarProps = {
  impersonatingUserName: string;
};

export default function ImpersonateUserAppBar({
  impersonatingUserName,
}: ImpersonateUserAppBarProps) {
  const mutation = useUpdateMutation({
    url: 'Login/StopImpersonate',
    onSuccess: (data) => {
      onSuccessEnqueueSnackbar(data);
      window.location.href = paths.home;
    },
  });
  const { mutate, isPending } = mutation;
  const [isToolbarAtBottom, setIsToolbarAtBottom] = useState(true);

  const handleCancelImpersonating = () => mutate({});

  const toggleToolbarPosition = () => setIsToolbarAtBottom((prev) => !prev);

  return (
    <>
      {isPending && <CasLoaderWithBackdrop />}
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          ...(isToolbarAtBottom && {
            top: 'auto',
            bottom: 0,
          }),
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
      >
        <Toolbar variant="dense" sx={{ justifyContent: 'space-between' }}>
          <CasIconButton icon="iconamoon:swap-fill" onClick={toggleToolbarPosition} />
          <Typography variant="h4">Impersonating {impersonatingUserName}</Typography>
          <CasIconButton icon="vaadin:close" onClick={handleCancelImpersonating} />
        </Toolbar>
      </AppBar>
    </>
  );
}
