import { useContext } from 'react';
// @mui
import Box from '@mui/material/Box';
// hooks
import useCasBoolean from 'src/shared/hooks/useCasBoolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { AuthContext} from 'src/site/context';
import { useSettingsContext } from 'src/components/settings';
// components
import { Main, Header, NavMini, NavVertical } from 'src/layouts/dashboard';
import { useCasAuthenticated } from 'src/shared/hooks/useUidParam';

import ImpersonateUserAppBar from './ImpersonateUserAppBar';

type Props = {
  children: React.ReactNode;
};

export default function SiteLayout({ children }: Props) {
  const settings = useSettingsContext();
  const {isAuthenticated} = useCasAuthenticated();

  const lgUp = useResponsive('up', 'lg');

  const nav = useCasBoolean();

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.setFalse} />;

  const { impersonatingUserName } = useContext(AuthContext);

  return (
    <>
      <Header onOpenNav={nav.setTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'row' },
        }}
      >
        {isAuthenticated && (lgUp && isMini ? renderNavMini : renderNavVertical)}

        <Main>{children}</Main>

        {impersonatingUserName && (
          <ImpersonateUserAppBar impersonatingUserName={impersonatingUserName} />
        )}
      </Box>
    </>
  );
}
