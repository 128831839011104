import { useRetrieveEntityList } from 'src/shared/hooks/reactQuery/useRetrieveEntityList';
import { Entity } from 'src/site/util';
import prependNoneOption from 'src/shared/util/prependNoneOption';
import valueClassname from 'src/shared/util/valueClassname';

import CasRHFSelect from '../CasSelect/CasRHFSelect';
import CasSelect from '../CasSelect/CasSelect';

export function useRiskWithSalesManager() {
  const { data, isLoading } = useRetrieveEntityList({entity: Entity.RISK_MANAGER_WITH_SALES});
  return [data?.result || [], isLoading];
}

export function CasSimpleRiskWithSalesManagerSelect({
  value,
  label,
  includeNoneOption,
  ...rest
}: any) {
  const [options] = useRiskWithSalesManager();

  const selectOptions = includeNoneOption ? prependNoneOption(options) : options;

  return (
    <CasSelect
      {...rest}
      value={value}
      className={valueClassname(value)}
      label={label}
      sx={{ minWidth: Math.max(115, label.length * 9) }}
      options={selectOptions}
      optionsLabelField="name"
      optionsValueField="id"
      />
  );
}

export default function CasRiskWithSalesManagerSelect({ includeNoneOption, ...rest }: any) {
  const [options, isLoading] = useRiskWithSalesManager();

  const selectOptions = includeNoneOption ? prependNoneOption(options) : options

  return <CasRHFSelect {...rest} loading={isLoading} options={selectOptions} />;
}
