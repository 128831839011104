import axios, { AxiosInstance } from 'axios';
import { IPublicClientApplication } from '@azure/msal-browser';

import { acquireAuthToken } from 'src/site/util';
import { isPublicLRPQuotes } from 'src/shared/hooks/useUidParam';

const baseURL = process.env.REACT_APP_API_URL as string;

export const axiosAuthInstance = (
  msalInstance: IPublicClientApplication,
  account: any,
  webUser: any
): AxiosInstance => {
  const instance = axios.create({ baseURL});

  instance.interceptors.request.use(async (config) => {
    if (isPublicLRPQuotes())
      config.headers.Authorization = `uid ${webUser}`;
    else {  
      const accessToken: any = await acquireAuthToken(msalInstance, account);
      config.headers.Authorization = `Bearer ${accessToken ?? ''}`;
    }
    return config;
  });

  return instance;
};
