import './SceCreateLogsDetails.scss'

import { CasDisplayField, CasDocumentationField, CasStackRow } from 'src/shared/components';
import { docusignDocURL } from 'src/application';
import { formatDate, DateFormat, formatDateTime } from 'src/shared/util/casMoment';

type SceLogsDetailsProps = {
  data: any;
};

export default function SceCreateLogsDetails({ data }: SceLogsDetailsProps) {
  const scesList = data?.SCEs?.map((sce: any) => sce.sce).join(', ');
  const lineOIDsList = data?.SCEs?.map((sce: any) => sce.premiumLineOID).join(', ');

  return (
    <>
      <CasStackRow>
        <CasDisplayField label="CY" value={data?.reinsuranceYear} />
        <CasDisplayField label="Policy#" value={data?.policyNumber} />
        <CasDisplayField label="SCEs" value={scesList} />
        <CasDisplayField label="Effective" value={formatDate(data?.effectiveDate, DateFormat.MDY)} />
        <CasDisplayField label="Id" value={data?.id} />
      </CasStackRow>
      <CasStackRow>
        <CasDisplayField label="Insured" value={data?.insuredName} />
        <CasDisplayField label="State" value={data?.state} />
        <CasDisplayField label="Agent" value={data?.agent} />
      </CasStackRow>
      <CasStackRow>
        <CasDisplayField label="User" value={data?.userName} />
        <CasDisplayField label="Created" value={formatDateTime(data?.dateCreated, DateFormat.MDY_HM)} />
        <CasDisplayField label="Line OIDs" value={lineOIDsList} />
      </CasStackRow>
      <CasDisplayField
        label="Docusign"
        value={
          <a
            href={docusignDocURL(data.docusignDomain, data.docusignId)}
            target="_blank"
            rel="noreferrer"
          >
            {data.docusignId}
          </a>
        }
      />
      <CasDocumentationField label="Log" value={data?.logText} className='CasDocumentationField logText' />
    </>
  );
}
