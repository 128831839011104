/* eslint-disable react-hooks/exhaustive-deps */
import "./CasAsOf.scss";

import React, { useEffect, useMemo } from 'react';
// @mui
import { Box, Stack, Typography } from '@mui/material';

// utils
import isDateAfter from 'src/shared/util/isDateAfter';
import { formatDate, DateFormat } from "src/shared/util/casMoment";
import { Colors } from 'src/shared/util/Colors';
import { usePeriodDates } from "src/shared/hooks/usePeriodDates";
import useCasBoolean from "src/shared/hooks/useCasBoolean";
// component
import { CasSelect, CasButton, CasStackRow } from "src/shared/components";
import CasIconButton from "src/shared/components/CasIconButton/CasIconButton";
import CasDatePopUp from './CasDatePopUp';
import { CasAsOfMode } from "./CasAsOfMode";

export const Custom_Label = 'Custom...';

function addDateToSortedList(dateList: string[], selectedDate: string) {
  const newDates = [...dateList, selectedDate];
  return newDates.sort();
}


interface ICasAsOf {
  mode: CasAsOfMode | null;
  selectedDate: string;
  asOfList: any;
  dateList: string[];
  setMode: React.Dispatch<React.SetStateAction<CasAsOfMode | null>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<string | null>>;
  setDateList: React.Dispatch<React.SetStateAction<string[]>>;
  // TODO - This surely isn't necessary?
  setShowLastAsOf: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CasAsOf({
  mode,
  setMode,
  dateList,
  setDateList,
  selectedDate,
  setSelectedDate,
  asOfList,
  setShowLastAsOf,
}: ICasAsOf) {
  const dialog = useCasBoolean();
  const { data } = usePeriodDates();
  const { currentPeriodEnd } = data;

  const futureMonthMessage = useMemo(() => {
    if (selectedDate !== Custom_Label && isDateAfter(selectedDate, currentPeriodEnd)) return 'Future Month';
    return '';
  }, [selectedDate]);

  // add date with sorting
  useEffect(() => {
      if (selectedDate === Custom_Label) dialog.setTrue();
      else {
        const exists = dateList.some((list) => list === selectedDate);
        if (!exists) setDateList(addDateToSortedList(dateList, selectedDate));
      }
  }, [selectedDate]);

  const handlePreviousClick = () => {
    const currentIndex = dateList.findIndex((item) => item === selectedDate);
    if (currentIndex > 0) setSelectedDate(dateList[currentIndex - 1]);
  };

  const handleNextClick = () => {
    const currentIndex = dateList.findIndex((item) => item === selectedDate);
    if (currentIndex < dateList.length - 1) setSelectedDate(dateList[currentIndex + 1]);
  };

  const handleNewClick = () => setMode(CasAsOfMode.NewAsOf);

  const handleCancelClick = () => {
    setShowLastAsOf(true);
    setDateList(
      asOfList.map((asOf: any) => (asOf.asOfDate))
    );
    const lastItem = asOfList?.[asOfList.length - 1];
    setSelectedDate(lastItem.asOfDate);
    setMode(CasAsOfMode.ViewEdit);
  };

  const asOfOptions = dateList.map((date) => {
    if (date === Custom_Label) {
      return { label: date, value: date };
    }
    return { label: formatDate(date, DateFormat.MDY), value: date };
  });

  return (
    <Stack className="CasAsOf control-no-margin" justifyContent="center" alignItems="center" flexDirection="column">
      <CasStackRow gapSize="none" alignItems="center" justifyContent="flex-end">
        {/* check for first as of list option */}
        {mode === CasAsOfMode.ViewEdit && selectedDate !== dateList[0] && (
          <CasIconButton icon="raphael:arrowleft" onClick={handlePreviousClick} />
        )}
        <Box sx={{position: 'relative'}}>
          <CasSelect
            label={mode === CasAsOfMode.NewAsOf ? 'Add new As of' : 'As of'}
            placeholder={mode === CasAsOfMode.NewAsOf ? 'Add new As of' : 'As of'}
            value={selectedDate}
            name="as_of"
            sx={{maxWidth:130, backgroundColor: 'white'}}
            onChange={(_, value) => setSelectedDate(value)}
            options={asOfOptions}
          />
          <Typography
            bgcolor={Colors.Present}
            variant="caption"
            fontStyle="italic"
            sx={{backgroundColor:"#f9b1e2", position:'absolute', top:40, left:12}}
          >
            {futureMonthMessage}
          </Typography>
        </Box>
        {/* check for last as of list option */}
        {mode === CasAsOfMode.ViewEdit &&
          selectedDate !== dateList[dateList.length - 1] && (
            <CasIconButton icon="raphael:arrowright" onClick={handleNextClick} />
          )}
        {/* check for last as of list option */}
        {mode === CasAsOfMode.ViewEdit &&
          selectedDate === dateList[dateList.length - 1] && (
            <CasIconButton
              icon="raphael:plus"
              onClick={handleNewClick}
              height={24}
              width={24}
            />
          )}

        {mode === CasAsOfMode.NewAsOf && asOfList.length > 0 && (
              <CasButton 
                color="error"
                sx={{fontWeight: "normal"}}
                size="small" variant="text" onClick={handleCancelClick}>
                Cancel
              </CasButton>
          )}

      </CasStackRow>
      {dialog.value && (
        <CasDatePopUp
          open={dialog.value}
          onClose={() => dialog.setFalse()}
          dateList={dateList}
          onDateSelected={setSelectedDate}
          initialAsOfList={asOfList}
        />
      )}
    </Stack>
  );
}
