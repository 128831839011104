import { m } from 'framer-motion';
// @mui
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// assets
import { SeverErrorIllustration } from 'src/assets/illustrations';
// components
import { MotionContainer, varBounce } from 'src/components/animate';
import { paths } from 'src/site/routes';
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export default function View503() {
  const handleRetryButtonClick = () => {
    window.location.href = paths.home;
  };

  return (
    <MotionContainer
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'inherit',
      }}
    >
      <m.div variants={varBounce().in}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          503 Service Unavailable
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>
          There was an error, please try again later.
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      </m.div>

      <Button
        component={RouterLink}
        size="large"
        variant="contained"
        onClick={handleRetryButtonClick}
      >
        Retry
      </Button>
    </MotionContainer>
  );
}
