/* eslint-disable  react-hooks/exhaustive-deps */
import "./CasMultiRowTable.scss";

import { useMemo, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableRow, Stack, IconButton, TableHead } from '@mui/material';
import Iconify from "src/components/iconify";
// hooks
import { useFieldArray, useFormContext } from 'react-hook-form';
// utils
import { getEmptyRow } from 'src/shared/util/getEmptyRow';
// components
import CasIconButton from '../CasIconButton/CasIconButton';
import CasRHFTextField from '../CasTextField/CasRHFTextField';
import CasRHFAutocomplete from '../CasAutoComplete/CasRHFAutocomplete';

interface IColumns {
  type: 'text' | 'select' | 'component' | 'cells';
  label: string;
  name: string;
  options?: any[];
  inputAdornment?: string;
  inputAdornmentType?: 'endAdornment' | 'startAdornment';
  inputAdornmentPosition?: 'end' | 'start';
  component?: (props: any) => JSX.Element;
  componentProps?: any;
  fullWidth?: boolean;
}

export interface ICasMultiRowTable {
  readOnly?: boolean;
  title?: string;
  row1Cell1?: any;
  rowCountMode?: 0 | 1 | 2;
  /*
    useFieldArray doesn't respond to form data changes external to its methods.
    replaceData should be used when the table should be updated after an external change.
    NOTES:
      - replaceData uses replace - which causes remount - which loses cursor position
      - The problem isn't useFieldArray. (Try using a TextField instead of CasAutocomplete - and it works)
       It appears to be either 
          (a) Bug in Autocomplete?
          (b) CasAutocomplete implem
  */
  replaceData?: any;
  columns: IColumns[];
    name: string;
  onRowDelete?: (index: number) => void; 
}

const CasMultiRowTable = ({
  readOnly=false,
  title,
  row1Cell1,
  name,
  rowCountMode=0,
  columns,
  replaceData,
  onRowDelete,
  }: ICasMultiRowTable) => {

  const [isEmptyRowAdded, setIsEmptyRowAdded] = useState<boolean>(false);
  const { setValue, control } = useFormContext();

  const { fields, append, remove, replace } = useFieldArray({ control, name });
  /*
    Note: useFieldArray assigns its own generated unique key
      to each row's "id" property
  */

  const emptyRow = useMemo(() => getEmptyRow(columns), []);

  useEffect(()=>{
    if (replaceData !== undefined)
      replace(replaceData);
  }, [replaceData]);


  useEffect(() => {
    if (!isEmptyRowAdded && rowCountMode > 0 && fields.length < rowCountMode) {
      const rowCountToAdd = rowCountMode - fields.length;

      Array.from({ length: rowCountToAdd }).forEach(() => {
        append(emptyRow);
      });
    }
      }, [fields, append]);

  useEffect(() => {
    if (fields.length > 0 && rowCountMode === 2 && row1Cell1)
      setValue(`${name}[0].${columns[0].name}`, row1Cell1);
  }, [fields, row1Cell1, setValue]);

  const renderColumns = (rowNbr: number) =>
    columns?.map((column: any, colNbr: number) => {
      const standardProps = {
        key: column.name,
        name: `${name}[${rowNbr}].${column.name}`,
        label: column.label,
        disabled: rowNbr === 0 && colNbr === 0 && rowCountMode === 2 ? true : readOnly,
        fullWidth: column.fullWidth || false,
      };

      const Component = column?.component;

      const key=`${name}[${rowNbr}].${column.name}`

      switch (column.type) {
        case 'select':
          return (
            <TableCell key={key}>
              <CasRHFAutocomplete {...standardProps} options={column.options} />
            </TableCell>
          );

        case 'text':
          return (
            <TableCell key={key}>
              <CasRHFTextField {...standardProps} />
            </TableCell>
          );

        case 'component':
          return (
            <TableCell key={key}>
              <Component {...standardProps} {...(column?.componentProps ?? {})} />
            </TableCell>
          );

        case 'cells':
          return (
            <Component {...standardProps} />
          );

        default:
          throw new Error('Unsupported column type');
      }
    });

  const handleRemoveRow = (index: number) => {
    if (onRowDelete) onRowDelete(index)
    setIsEmptyRowAdded(true);
    remove(index);
  }

  return (
    <Stack className='CasMultiRowTable' alignItems="center">
      <Table >
        <TableHead>
          {title &&
            <TableRow className="title">
              <TableCell colSpan={fields.length}>{title}</TableCell>
            </TableRow>}
        </TableHead>
        <TableBody>
          {fields.map((field, index) => (
            <TableRow key={field.id} sx={{ verticalAlign: 'super' }}>
              {renderColumns(index)}
              <TableCell key={`${name}[${index}].icons`}>
                {((rowCountMode === 2 && index !== 0 && fields?.length > 2) ||
                  rowCountMode === 0 || rowCountMode === 1) && (
                    <IconButton
                      disabled={readOnly}
                      className="delete"
                      sx={{opacity:.48, '&:hover':{opacity:1}}}
                      onClick={() => handleRemoveRow(index)}
                      color="error">
                      <Iconify icon="zondicons:minus-outline" width="25" height="25" />
                    </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CasIconButton
        icon="gridicons:add-outline"
        onClick={() => append(emptyRow)}
        disabled={readOnly}
        color="primary"
      />
    </Stack>
  );
};

export default CasMultiRowTable;
