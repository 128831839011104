import { CasRHFTextField } from 'src/shared/components';

export default function CasNumberField(props: any) {
  const labelLength = props?.label?.length ?? 0;
  return (
    <CasRHFTextField
      {...props}
      type="number"
      sx={{ maxWidth: Math.max(130, labelLength * 7), pl: 0 }}
    />
  );
}
