import { Stack, Typography } from '@mui/material';
import { CasButton } from 'src/shared/components';

interface LrpTypeProps {
  className: string;
  types: Array<{
    typeName: string;
    targetLowWeightLbs: number;
    targetHighWeightLbs: number;
  }>;
  selectedType: { typeName: string };
  onTypeChange: (type: any) => void;
}

export default function LrpTypeSelector({
  className,
  types,
  selectedType,
  onTypeChange,
}: LrpTypeProps) {
  return (
    <Stack flexDirection="row" justifyContent="space-around" my={2}>
      {types.length === 1 ? (
        <Typography className={className}>
          {types[0].typeName} ({types[0].targetLowWeightLbs}-{types[0].targetHighWeightLbs}lbs)
        </Typography>
      ) : (
        types.map((type) => {
          const isSelected = selectedType.typeName === type.typeName;
          const selectedClassName = isSelected ? 'selected' : '';

          return (
            <CasButton
              key={type.typeName}
              className={`${className}-button ${selectedClassName}`}
              onClick={() => onTypeChange(type)}
            >
              <Typography className={`${className}-typeName ${selectedClassName}`}>
                {type.typeName}
              </Typography>
              <Typography className={`${className}-weights ${selectedClassName}`}>
                ({type.targetLowWeightLbs}-{type.targetHighWeightLbs}lbs)
              </Typography>
            </CasButton>
          );
        })
      )}
    </Stack>
  );
}
