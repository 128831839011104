import { CasPopoverMenu, CasStackRow } from 'src/shared/components';
import { useExportOption } from 'src/shared/util/useExportOption';

export default function CasMRTableExportOptionMenu(
  entity: string,
  params?: Record<string, any>
) {
  const { exportOption } = useExportOption({ entity, params });

  return (
    <CasStackRow flexGrow={1} justifyContent="flex-start">
      <CasPopoverMenu options={exportOption} />
    </CasStackRow>
  );
}
