import { DateFormat, formatDate, getDateInMoment } from 'src/shared/util/casMoment';

type DateValuesProps = {
  numberOfMonths: number;
};

interface DateOptionsProps extends DateValuesProps {
  format?: DateFormat;
}

export function useDateValues({ numberOfMonths }: DateValuesProps) {
  const dates = [];
  let currentDate = getDateInMoment();

  while (dates.length < numberOfMonths) {
    dates.push(formatDate(currentDate, DateFormat.YMD));
    currentDate = currentDate.add(1, 'month').startOf('month');
  }

  return dates;
}

export function useDateOptions({ format = DateFormat.MDY, numberOfMonths }: DateOptionsProps) {
  const dateValues = useDateValues({ numberOfMonths });

  const datesOptions = dateValues.map((date) => ({
    label: formatDate(date, format),
    value: date,
  }));

  return datesOptions;
}
