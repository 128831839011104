import '../styles/lrpQuotes.scss';

import { useEffect, useLayoutEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { CasButton, CasTabsView } from 'src/shared/components';
import useAddMutation from 'src/shared/hooks/reactQuery/useAddMutation';

import LrpQuotesTab from './LrpQuotesTab';

function getLevelsData(levels: any) {
  return levels.map(({ endDate, subRows }: any) => {
    const [firstSubRow, ...remainingSubRows] = subRows;
    return {
      endDate,
      ...firstSubRow,
      subRows: remainingSubRows.map((remainSubRow: any) => ({ ...remainSubRow, endDate })),
    };
  });
}

function getProcessedQuotesData(data: any[]) {
  return data.reduce((acc, item: any) => {
    const processedItem = {
      ...item,
      types: item.types.map((type: any) => ({
        ...type,
        levels: getLevelsData(type.levels),
      })),
    };
    acc[item.tab] = processedItem;
    return acc;
  }, {});
}

export default function LrpQuotesContent({ data }: any) {
  const { mutate } = useAddMutation({
    url: 'LRPQuotes/UpdateUserSetting',
    onSuccess: () => null,
  });

  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [processedData, setProcessedData] = useState<Record<string, any>>({});

  useLayoutEffect(() => {
    if (data) {
      const { result, tabName } = data;
      setActiveTab(tabName);
      setProcessedData(getProcessedQuotesData(result));
    }
  }, [data]);

  useEffect(() => {
    if (activeTab) mutate({ tabName: activeTab });
  }, [activeTab, mutate]);

  const tabList = [
    {
      label: 'Fed',
      children: <LrpQuotesTab className='fed-tab-quoteType' tabData={processedData.Fed} />,
      className: 'fed-tab',
    },
    {
      label: 'Feeders',
      children: <LrpQuotesTab className='feeders-tab-quoteType' tabData={processedData.Feeders} />,
      className: 'feeders-tab',
    },
    {
      label: 'Calves',
      children: <LrpQuotesTab className='calves-tab-quoteType' tabData={processedData.Calves} />,
      className: 'calves-tab',
    },
    {
      label: 'Unborn',
      children: <LrpQuotesTab className='unborn-tab-quoteType' tabData={processedData.Unborn} />,
      className: 'unborn-tab',
    },
  ];

  if (activeTab)
    return (
      <CasTabsView
        tabList={tabList}
        defaultActiveTab={activeTab}
        onActiveTabChange={setActiveTab}
      />
    );

  return (
    <Stack gap={3} alignItems="center">
      {tabList.map((tab) => (
        <CasButton
          key={tab.label}
          variant="outlined"
          onClick={() => setActiveTab(tab.label)}
          className={`${tab.className}-button`}
        >
          {tab.label}
        </CasButton>
      ))}
    </Stack>
  );
}
