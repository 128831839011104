import { useRetrieveEntityList } from 'src/shared/hooks/reactQuery/useRetrieveEntityList';
import { Entity } from 'src/site/util';

export function useRiskWithSalesManager() {
  const { data, isLoading } = useRetrieveEntityList({entity: Entity.RISK_MANAGER_WITH_SALES});
  return [
    data?.result?.map((option: any) => ({
      value: option.id,
      label: option.name,
    })) || [],
    isLoading,
  ];
}
