import { useEffect } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowData,
  type MRT_TableOptions,
} from 'material-react-table';
import { UseQueryResult } from '@tanstack/react-query';
import { useCasMRTable } from 'src/shared/hooks/useCasMRTable';
import defaultLabelFromField from 'src/shared/util/defaultLabelFromField';
import { ICasMRTCellProps } from '../CasMRTCellRender/ICasMRTCellProps';
import CasMRTableFilter from './CasMRTableFilter';
import CasLoader from '../CasLoader/CasLoader';

export type ModifiedMRT_ColumnDef<TData extends object = {}> = Omit<
  MRT_ColumnDef<TData>,
  'header'
> & {
  header?: MRT_ColumnDef<TData>['header'];
};

type CasMRTableProps = Partial<Omit<MRT_TableOptions<MRT_RowData>, 'columns'>> & {
  queryResult?: UseQueryResult<MRT_RowData[], any>;
  data?: MRT_RowData[];
  columns?: ModifiedMRT_ColumnDef<MRT_RowData>[];
  createColumnsFromData?: boolean;
  overrideColumns?: Record<string, Record<string, any>>;
  showToolbarCustomAction?: boolean;
  tableTitle?: Function;
  filtersList?: string[];
  hideGroupingHeader?: boolean;
};

export default function CasMRTable({
  queryResult,
  data,
  showToolbarCustomAction = true,
  tableTitle,
  filtersList = [],
  columns,
  enableRowVirtualization = false,
  enableColumnVirtualization = false,
  enablePagination = true,
  enableExpanding = false,
  muiPaginationProps,
  initialState,
  hideGroupingHeader = false,
  ...rest
}: CasMRTableProps) {
   if (data && queryResult)
    throw Error("Can't specify both queryResult and data.");

  if (!data && !queryResult)
    throw Error("Either queryResult or data must be specified.");

  const table = useCasMRTable({
    queryResult,
    data,
    ...(showToolbarCustomAction && {
      renderTopToolbarCustomActions: ({ table: tableProp }: Pick<ICasMRTCellProps, 'table'>) =>
        tableProp.getState().isFullScreen && tableTitle && tableTitle({ table }),
    }),
    enableRowVirtualization,
    enableColumnVirtualization,
    ...(columns && {
      columns: columns.map((column) => ({
        ...column,
        header: column.header ? column.header : defaultLabelFromField(column.accessorKey || ''),
      })),
    }),
    enablePagination,
    ...(enablePagination && {
      muiPaginationProps: {
        rowsPerPageOptions: [15, 25],
        ...muiPaginationProps,
      },
    }),
    enableExpanding,
    initialState: {
      ...(enablePagination && {
        pagination: { pageIndex: 0, pageSize: 25 },
      }),
      ...(enableExpanding && { expanded: {} }),
      ...initialState,
    },
    muiBottomToolbarProps: {
      sx: enablePagination ? undefined 
          : {maxHeight: '1px', minHeight: '1px'}  // when not paginated, shorten bottom toolbar
    },
    ...rest,
  });

  // reset filters when api response changes
  useEffect(() => {
    if (queryResult?.isFetching) table.setColumnFilters([]);
  }, [queryResult?.isFetching, table]);

  return (
    <>
      {tableTitle && tableTitle({ table })}

      {queryResult && queryResult.isLoading ? (
        <CasLoader />
      ) : (
        <>
          {filtersList.length > 0 &&
            table.getAllColumns().length > 0 &&
            filtersList.map((columnId) => (
              <CasMRTableFilter
                columnId={columnId}
                table={table}
                resetFilters={queryResult?.isFetching ?? false}
              />
            ))}

          <div
            className={`MRTable ${
              enableRowVirtualization || enableColumnVirtualization
                ? 'MRTable-grid'
                : 'MRTable-standard'
            } 
            ${hideGroupingHeader && 'MRTable-hide-grouping-header'}
            `}
          >
            <MaterialReactTable table={table} />
          </div>
        </>
      )}
    </>
  );
}
