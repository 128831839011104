import { m } from 'framer-motion';
import { useMsal } from '@azure/msal-react';
import { useCasAuthenticated } from 'src/shared/hooks/useUidParam';
// @mui
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
// assets
import { paths } from 'src/site/routes';
import { SeverErrorIllustration } from 'src/assets/illustrations';
// components
import { MotionContainer, varBounce } from 'src/components/animate';
import CasLink from 'src/shared/components/CasLink/CasLink';
import { loginRequest } from 'src/site/config/auth.config';

export default function View401() {
  const { instance } = useMsal();
  const activeAccount = instance?.getActiveAccount();

  const {isAuthenticated} = useCasAuthenticated();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  const handleRetryButtonClick = () => {
    window.location.href = paths.home;
  };

  const handleLogout = () => instance.logoutRedirect();

  const getRenderAuthButton = () => {
    if (isAuthenticated)
      return (
        <>
          {activeAccount && activeAccount.username && (
            <Typography>{activeAccount.username}</Typography>
          )}
          <Stack gap="1rem" flexDirection="row" mt="1rem" alignItems="center">
            <Button onClick={handleRetryButtonClick} size="large" variant="contained">
              Go to Home
            </Button>
            <CasLink onClick={handleLogout}>Logout</CasLink>
          </Stack>
        </>
      );
    return (
      <Button onClick={() => handleLogin()} size="large" variant="contained">
        Re-Login
      </Button>
    );
  };

  return (
    <MotionContainer
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'inherit',
      }}
    >
      <m.div variants={varBounce().in}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Not Authorized
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>
          The page you&apos;re trying access has restricted access.
          <br />
          Please contact to Site Administration.
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      </m.div>

      {getRenderAuthButton()}
    </MotionContainer>
  );
}
