import { MenuItem } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CasRHFSelectBase, CasRHFSelectBaseProps } from './CasRHFSelectBase';

export type CasRHFSelectProps = Omit<
  CasRHFSelectBaseProps,
  'currentValue' | 'children' | 'onChange'
> & {
  options: any[];
  optionsIdField?: string;
  optionsNameField?: string;
  loading?: boolean;
  onChange?: (event: { target: { value: any } }, value: any) => void;
};

export type CasEntitySelectProps =  Omit<CasRHFSelectProps, 'options'>

export default function CasRHFSelect({
  options,
  optionsIdField = 'id',
  optionsNameField = 'name',
  defaultValue,
  name,
  onChange,
  ...rest
}: CasRHFSelectProps) {
  const { setValue, getValues, control } = useFormContext();

  const currentValue = useWatch({control, name});

  // Set the default value when the component mounts
  useEffect(() => {
    const selectValue = getValues(name);
    if (defaultValue && !selectValue) setValue(name, defaultValue);
  }, [setValue, name, defaultValue, getValues]);

  useEffect(() => {
    if (onChange){ 
      onChange({ target: { value: currentValue } }, currentValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);


  return (
    <CasRHFSelectBase
      {...rest}
      name={name}
      defaultValue={defaultValue}
    >
      {options.map((option: any) => (
        <MenuItem key={option[optionsIdField]} value={option[optionsIdField]}>
          {option[optionsNameField]}
        </MenuItem>
      ))}
    </CasRHFSelectBase>
  );
}
