import { useRetrieveEntityList } from 'src/shared/hooks/reactQuery/useRetrieveEntityList';
import { Entity } from 'src/site/util';
import CasRHFSelect, { CasEntitySelectProps } from '../CasSelect/CasRHFSelect';

export function useRiskManager() {
  const { data, isLoading } = useRetrieveEntityList({entity: Entity.EMPLOYEE_RISK_MANAGER});
  return [data?.result || [], isLoading];
}

export default function CasRiskManagerSelect(props: CasEntitySelectProps) {
  const [options, isLoading] = useRiskManager();

  return <CasRHFSelect {...props} loading={isLoading} options={options} />;
}
