import 'src/shared/theme/app-theme.scss';

import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// layouts
import { SiteLayout, MenuPageLayout } from 'src/application';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { CasAuthenticationHOC } from 'src/site/components';
import { paths } from './paths';
import { useCasFilteredNavigation } from './useCasFilteredNavigation';

// ----------------------------------------------------------------------

const HomePage = lazy(() => import('src/pages/home/HomePage'));

function useDashboardRoutes() {
  const navData = useCasFilteredNavigation();

  const dashBoardRoutes = navData.map((menu) => ({
    path: menu.path,
    exact: true,
    children: [
      {
        // Giving path as an empty string means it will match the parent path.
        path: '',
        exact: true,
        element: <CasAuthenticationHOC Component={<MenuPageLayout subMenus={menu.subMenus} />} />,
      },
      ...menu.subMenus.map((subMenu) => ({
        path: subMenu.path,
        exact: true,
        element: subMenu.element ? <CasAuthenticationHOC Component={subMenu.element} /> : null,
      })),
    ],
  }));

  const additionalRoutes = [
    {
      path: paths.home,
      permissions: ['*'],
      element: (
        <SiteLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </SiteLayout>
      ),
      children: [
        {
          path: '',
          permissions: ['*'],
          element: <Navigate to={paths.DASHBOARD} replace />,
        },
        {
          path: paths.DASHBOARD,
          permissions: ['*'],
          element: <HomePage />,
        },
        ...dashBoardRoutes,
      ],
    },
  ];

  return additionalRoutes;
}

export default useDashboardRoutes;
