import { createContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { isPublicLRPQuotes } from 'src/shared/hooks/useUidParam';

import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useNavigate } from 'react-router-dom';
import { acquireAuthToken, IContextChildren, IAuthContext } from 'src/site/util';
import { LoadingScreen } from 'src/components/loading-screen';
import useRetrieve from 'src/shared/hooks/reactQuery/useRetrieve';
import { onErrorPermissionsHandler } from 'src/shared/util/onErrorHandlers';
import infoUriFromEntity from 'src/shared/util/infoUriFromEntity';
import { paths } from '../routes';

export const AuthContext = createContext<IAuthContext>({
  token: null,
  userPermissions: null,
  loadingPermission: true,
  account: null,
  impersonatingUserName: null,
});

const AuthContextProvider = ({ children }: IContextChildren) => {
  const { instance, inProgress } = useMsal();
  const [token, setToken] = useState<string | null | undefined>(null);
  const [account, setAccount] = useState<any>(null);
  const [loadingPermission, setLoadingPermission] = useState(true);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { data } = useRetrieve({
    url: infoUriFromEntity('Login/Permission'),
    enabled: !!token,
    onError : (error: AxiosError) => {
      setLoadingPermission(false);
      onErrorPermissionsHandler(error);
    },
    onSuccess: (response: any) => {
      setLoadingPermission(false);
      return response;
    }
  });

  const { authorities, impersonatingUserName } = data || {};

  useEffect(() => {
    if (data !== undefined) {
      if (!authorities || authorities?.length === 0) navigate(paths.page401);
    }
  }, [data, authorities, navigate]);

  useEffect(() => {
    if (!token && inProgress === InteractionStatus.None) {
      const accountInfo = instance.getActiveAccount();
      setAccount(accountInfo);

      if (isPublicLRPQuotes()) {
        setToken(undefined);
      } else {
        (async () => {
          if (accountInfo) {
            const accessToken: any = await acquireAuthToken(instance, accountInfo);
            setToken(accessToken);
          }
        })();
      }
    }
  }, [inProgress, instance, token, enqueueSnackbar]);

  const valueObj = useMemo(
    () => ({
      token: token ?? null,
      userPermissions: authorities || [],
      loadingPermission,
      impersonatingUserName,
      account,
    }),
    [token, impersonatingUserName, authorities, loadingPermission, account]
  );

  if ((loadingPermission || !token) && account
      && token!==undefined) {
    return <LoadingScreen />;
  }

  return <AuthContext.Provider value={valueObj}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
